/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import React, { useContext, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { regularTextStyle } from '../atom/Text';
import { WidgetContext } from '../atom/WidgetContext';
import { marked } from '../../util/marked';
import { compareOnlyProperties } from '@s/compareOnlyProperties';
import { required } from '@s/assertions';
import { MessageSender } from '@s/domain/entity/MessageFormat';
import { useRefState } from '@s/reactHooks';

const MessageContainer = styled.div<{ type: string }>`
  padding: 10px;
  border-radius: ${p => (p.type === 'web' ? 4 : 15)}px;
  overflow-wrap: break-word;
  word-break: break-word;
`;

const TextContainerElement = styled.div<{ linkColor: string }>`
  ${regularTextStyle};
  a {
    color: ${p => p.linkColor} !important;
  }
`;
const CustomerTextContainerElement = styled.div`
  ${regularTextStyle};
  white-space: pre-wrap;
`;

export interface WidgetPlainTextMessageProps {
  sender: MessageSender;
  text: string;
  theme?: 'instagram' | 'web';
}

const TARGET_BLANK_LINK = `<a target="_blank" rel="noreferrer noopener nofollow" `;
export const WidgetPlainTextMessage = compareOnlyProperties(
  ({ sender, text, theme = 'web' }: WidgetPlainTextMessageProps) => {
    const { config } = useContext(WidgetContext);
    const ref = useRef<HTMLElement>();
    const [markdownHtml, setMarkdownHtml] = useRefState(marked(text));
    useEffect(() => {
      if (ref.current) {
        const handler = (e: MouseEvent) => {
          const el = e.target as HTMLLinkElement;
          if (el.nodeName === 'A') {
            e.preventDefault();
            config.dispatch('linkClick', {
              text: el.textContent,
              href: el.href,
            });
            try {
              required(window.open(el.href)).location.href = el.href;
            } catch (e) {
              location.href = el.href;
            }
          }
        };
        ref.current.addEventListener('click', handler);
        return () => {
          ref.current && ref.current.removeEventListener('click', handler);
        };
      }
    }, [ref.current]);

    useEffect(() => {
      if (sender === 'Bot') {
        const fragment = document.createDocumentFragment();
        const outer = document.createElement('div');
        fragment.appendChild(outer);
        outer.innerHTML = marked(text).replace(/<a /, TARGET_BLANK_LINK);
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-arguments
        outer.querySelectorAll<HTMLElement>('*').forEach(el => {
          el.style.cssText.split(';').forEach(s => {
            if (s) {
              const splitted = s.split(':');
              const propertyName = splitted[0].trim();
              const value = splitted[1].trim();
              el.style.setProperty(propertyName, value, 'important');
              if (propertyName === 'color') {
                el.style.setProperty('-webkit-text-fill-color', value, 'important');
              }
            }
          });
        });
        setMarkdownHtml(outer.innerHTML);
      }
    }, [text]);

    return (
      <MessageContainer
        type={theme}
        css={{
          background:
            theme === 'web'
              ? config.chatColoring[sender].background
              : sender === 'Customer'
              ? 'rgb(88,74,204)'
              : 'rgb(239,239,239)',
          color:
            theme === 'web'
              ? config.chatColoring[sender].color
              : sender === 'Customer'
              ? '#FFFFFF'
              : '#222222',
          // For safari
          WebkitTextFillColor:
            theme === 'web'
              ? config.chatColoring[sender].color
              : sender === 'Customer'
              ? '#FFFFFF'
              : '#222222',
        }}
      >
        {sender === 'Bot' || sender === 'Operator' ? (
          <TextContainerElement
            ref={ref as any}
            className="aim__widget-plain-text-message__md"
            dangerouslySetInnerHTML={{ __html: markdownHtml.current }}
            linkColor={config.chatColoring.common.color}
          />
        ) : (
          <CustomerTextContainerElement>{text}</CustomerTextContainerElement>
        )}
      </MessageContainer>
    );
  },
  'WidgetPlainTextMessage'
);
