/**
 * @fileOverview
 * @name MessageService.ts
 * @author Taketoshi Aono
 * @license
 */

import {
  ImageTextAttachment,
  PlainTextAttachment,
  QuickReplyPostbackAttachment,
} from '@s/domain/entity/MessageFormat';
import {
  DisplayableMessageFormat,
  convertMessageFormatToWidgetMessage,
} from '@s/components/atom/WidgetMessageConfig';
import { EventRequestParam } from '@s/domain/values/EventRequestParam';
import { MessageState } from '@w/domain/entities/State';

export interface MessageDomainService {
  batchUpdateMessages(messages: DisplayableMessageFormat[]): void;
  updateMessage(message: DisplayableMessageFormat): DisplayableMessageFormat[];
  insertDummy(param: EventRequestParam): void;
  setTyping(): void;
  createDummyMessageData(param: EventRequestParam): DisplayableMessageFormat;
  deleteDummyMessage(uuid: string): DisplayableMessageFormat | null;
}

export class MessageService {
  public constructor(private readonly state: MessageState) {}

  public batchUpdateMessages(messages: DisplayableMessageFormat[]) {
    if (!messages.length) {
      return;
    }
    for (const m of messages) {
      this.addMessage(m, false);
    }
    this.state.messages.sort((a, b) => a.at - b.at);
    this.hideQuickReplies(true);
  }

  /**
   * ダミーデータ生成
   * @param {*} param
   * @return {*}
   * `
   */
  public createDummyMessageData(param: EventRequestParam): DisplayableMessageFormat {
    let attachment: PlainTextAttachment | QuickReplyPostbackAttachment | ImageTextAttachment;
    switch (param.type) {
      case 'postback':
        if (!param.postback) {
          throw new Error('Illegal parameter received');
        }
        attachment = {
          mediaType: 'postback',
          payload: param.postback,
        };
        break;
      case 'image':
        if (!param.imageUrl) {
          throw new Error('Illegal parameter received');
        }
        attachment = {
          mediaType: 'image',
          payload: {
            url: param.imageUrl.url,
            previewUrl: param.imageUrl.previewUrl,
          },
        };
        break;
      default:
        if (!param.message) {
          throw new Error('Illegal parameter received');
        }
        attachment = {
          mediaType: 'plainText',
          payload: param.message,
        };
    }
    return convertMessageFormatToWidgetMessage({
      // 必ずダミーデータをメッセージの末尾に追加するため`.at + 1`する
      // （Date.now()だとユーザー端末の時間に依存してしまうためNG）
      at:
        this.state.messages.length > 1
          ? this.state.messages[this.state.messages.length - 1].at + 1
          : Date.now(),
      viewAt: Date.now(),
      id: param.uuid,
      messages: [
        {
          attachment: attachment,
          id: 'dummy',
        },
      ],
      meta: {
        uuid: param.uuid,
      },
      sender: {
        type: 'Customer',
        id: '',
      },
      type: param.type,
    })[0];
  }

  /**
   * メッセージ削除
   * @param {*} uuid
   * @return {*}
   */
  public deleteDummyMessage(uuid: string): DisplayableMessageFormat | null {
    const removeIndex = this.state.messages.findIndex(msg => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      return msg?.uuid === uuid;
    });
    if (removeIndex > -1) {
      const dummyMessage = this.state.messages[removeIndex];
      this.state.messages.splice(removeIndex, 1);
      return dummyMessage;
    }
    return null;
  }

  public insertDummy(param: EventRequestParam) {
    // すでに同一UUIDのdummy挿入済みなら削除
    this.deleteDummyMessage(param.uuid);
    const dummyData = this.createDummyMessageData(param);
    if (this.state.typing.visible) {
      this.state.messages[this.state.messages.length - 1].at = new Date().getTime();
      this.state.messages.splice(this.state.messages.length - 1, 0, dummyData);
    } else {
      this.state.messages.push(dummyData);
    }
    this.hideQuickReplies();
  }

  /**
   * removeTyping
   */
  public removeTyping() {
    if (this.state.messages.length === 0) {
      return;
    }
    this.state.typing.visible = false;
  }

  /**
   * setTyping
   */
  public setTyping() {
    if (this.state.typing.visible) {
      return;
    }
    this.state.typing.visible = true;
  }

  /**
   * updateMessage
   * @param {*} message
   * @return {*}
   */
  public updateMessage(message: DisplayableMessageFormat): DisplayableMessageFormat[] {
    // 同一UUIDのダミーメッセージがあったら削除する
    if (message.sender.type === 'Customer') {
      const widgetMessage = this.deleteDummyMessage(message.meta.uuid);
      if (widgetMessage) {
        message.at = widgetMessage.at;
        message.viewAt = widgetMessage.viewAt;
      }
    }

    if (this.state.typing.visible && message.sender.type !== 'Customer') {
      this.removeTyping();
      this.addMessage(message);
      if (message.meta?.uuid && message.meta.uuid.includes('-$seq')) {
        this.setTyping();
      }
    } else if (this.state.typing.visible) {
      this.state.messages.splice(this.state.messages.length - 1, 0, message);
    } else {
      this.addMessage(message);
    }

    return this.state.messages.sort((a, b) => a.at - b.at);
  }

  /**
   * addMessage
   * @param {DisplayableMessageFormat} message
   * @private
   */
  private addMessage(message: DisplayableMessageFormat, shouldHideQuickReplies = true) {
    if (shouldHideQuickReplies) {
      this.hideQuickReplies();
    }
    if (!this.state.messages.find(currentMessage => currentMessage.uuid === message.uuid)) {
      this.state.messages.push(message);
    }
  }

  public modifyMessage(message: DisplayableMessageFormat) {
    this.state.messages = this.state.messages.map(m => {
      if (m.uuid === message.uuid) {
        return message;
      }
      return m;
    });
  }

  private hideQuickReplies(isCheckAll = false) {
    if (this.state.messages.length) {
      const start = this.state.messages.length - 1;
      for (let i = start; i >= 0; i--) {
        const message = this.state.messages[i];
        if (i !== start && message.sender.type === 'Bot') {
          message.quickReplies = undefined;
          if (!isCheckAll) {
            break;
          }
        }
      }
    }
  }
}
