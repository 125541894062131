import marked from 'marked';

marked.setOptions({
  gfm: true,
  breaks: true,
  smartLists: true,
  smartypants: false,
  xhtml: true,
});

marked.use({
  renderer: {
    paragraph(text: string) {
      return `<span>${text}</span>`;
    },
  } as any,
  tokenizer: {
    space(text: string) {
      const breaks = /^ *\n/.exec(text);
      if (breaks) {
        return {
          type: 'html',
          raw: breaks[0],
          text: '<br/>',
        } as any;
      }
    },
  } as any,
});

export { marked };
